import React from "react";
import styled from "@emotion/styled";
import { Box, Label, Text } from "theme-ui";
import { useField } from "formik";

const TextArea = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <Wrapper mb={["21px", "42px"]}>
      <Text
        as="label"
        sx={{ display: "block" }}
        variant="formLabel"
        mb={[2, 3]}
      >
        Message
      </Text>
      <StyledTextArea as="textarea" {...field} />
    </Wrapper>
  );
};
export default TextArea;

const Wrapper = styled(Box)``;

const StyledTextArea = styled(Box)`
  display: block;
  width: 100%;
  min-height: 109px;
  outline: none;
  padding: 0.8rem;
  resize: none;
  border-radius: none;
  border-color: ${({ theme }) => theme.colors.grey[8]};
  font-family: inherit;
  ${({ theme }) =>
    theme.mq({
      fontSize: ["16px", "21px"],
    })};
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
`;
