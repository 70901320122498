import styled from "@emotion/styled";
import React from "react";
import { Box, Text } from "theme-ui";
import { Field, useField } from "formik";

const Switch = ({ name, label }) => {
  return (
    <Wrapper role="group" aria-labelledby="radio-group" mb={[2, 3]}>
      <Field type="radio" name={name} id={`${name}-on`} value="yes" />
      <label htmlFor={`${name}-on`}>
        <Text variant="textLabel">Yes</Text>
      </label>
      <Field type="radio" id={`${name}-off`} name={name} value="no" />
      <label htmlFor={`${name}-off`}>
        <Text variant="textLabel">No</Text>
      </label>
      <Label ml={[2, 3]} variant="formLabel">
        {label}
      </Label>
    </Wrapper>
  );
};

export default Switch;

const Label = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[3]}; ;
`;

const Wrapper = styled(Box)`
  line-height: 100%;
  display: flex;
  align-items: center;

  & input {
    position: absolute;
    opacity: 0;
    height: 0;
    margin: 0;
    bottom: 0;
    visibility: hidden;
  }
  & input + label {
    display: inline-block;
    color: ${({ theme }) => theme.colors.grey[6]};
    cursor: pointer;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.grey[1]};
    ${({ theme }) =>
      theme.mq({
        fontSize: ["10px", "15px"],
        padding: ["4px 8px", "6px 11px"],
      })};
    line-height: 100%;
    text-transform: uppercase;

    letter-spacing: 1px;
    font-family: inherit;
    font-family: ${({ theme }) => theme.fonts.bold};
  }

  & input:checked + label {
    background-color: ${({ theme }) => theme.colors.grey[1]};
    color: white;
  }

  & label:first-of-type {
    border-right: 1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  & label:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
