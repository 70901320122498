import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { useField } from "formik";
import ErrorMessage from "./ErrorMessage";

const PhoneField = ({ helperText, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (a, b, c) => {
    helpers.setValue(c?.target?.value);
    helpers.setTouched(true);
  };

  return (
    <Wrapper mb={["21px", "42px"]}>
      <PhoneInput
        country="au"
        preferredCountries={["au"]}
        autoFormat={false}
        placeholder="+61412345678"
        countryCodeEditable={false}
        onChange={handleChange}
        inputProps={{ name: field.name }}
        value={field.value}
      />
      {helperText && <HelperText mt={[1, 2]}>{helperText}</HelperText>}
      {meta?.error && meta?.touched && <ErrorMessage message={meta?.error} />}
    </Wrapper>
  );
};
export default PhoneField;

const Wrapper = styled(Box)`
  width: 100%;
  * {
    color: ${({ theme }) => theme.colors.grey[1]};
  }

  .react-tel-input .form-control {
    width: 100%;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[8]};
    border-radius: 0;
    font-family: ${({ theme }) => theme.fonts.body};
    ${({ theme }) =>
      theme.mq({
        fontSize: ["16px", "21px"],
      })};
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    &::placeholder {
      color: ${({ theme }) => theme.colors.grey[6]};
    }
  }

  .react-tel-input .flag-dropdown {
    background-color: transparent;
    border: none;
    font-family: ${({ theme }) => theme.fonts.body};
  }
`;

const HelperText = styled(Box)`
  color: ${({ theme }) => theme.colors.grey[6]};
  line-height: 100%;
  font-size: 15px;
`;
